<template>
  <div v-if="perms.includes('Admin')">
   <div v-if="loading">
      <div id="loading-bg">
      <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</div>
  <b-card v-else >
      <!-- input search -->
      <div class="custom-search">
      <b-row>
          <b-col md="3" >
          <b-form-group>
              <b-button
              variant="primary"
              :to="{ name: 'AddBonCmd'}"
          >
              Ajouter Bon de commande
          </b-button>
          </b-form-group>
          </b-col>
          <b-col md="9">
          <b-form-group>
          <div class="d-flex align-items-center">
          <label class="mr-1">Chercher</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Chercher "
              type="text"
              class="d-inline-block"
          />
          </div>
      </b-form-group>
          </b-col>
      </b-row>
      </div>
      <!-- <div class="custom-search d-flex justify-content-end">
          <b-button
              variant="primary"
              :to="{ name: 'facture-fournis-add'}"
          >
              Nouvelle Facture
          </b-button>
      <b-form-group>
          <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
          </div>
      </b-form-group>
      </div> -->
      <!-- table -->
      <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      responsive
      :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      :pagination-options="{
          enabled: true,
          perPage:pageLength
      }"
      >
      <template
          slot="table-row"
          slot-scope="props"
      >

          <!-- <div
          v-if="props.column.field === 'NumFact'"
          class="text-nowrap"
          >
          <span ># {{ props.row.NumFact }}</span>
          </div> -->
          <span
          v-if="props.column.field === 'NomSociete'"
          class="text-nowrap"
          >
          <b-avatar
          :src="`${path}${props.row.logo}`"
              class="mx-1"
          />
          <span>{{ props.row.NomSociete }}</span>
          </span>
      <!--  <div
          v-if="props.column.field === 'TotalHT'"
          class="text-nowrap"
          >
          <span class="text-nowrap"> {{ props.row.TotalHT }} Dhs</span>
          </div>
          <div
          v-if="props.column.field === 'TotalNet'"
          class="text-nowrap"
          >
          <span class="text-nowrap"> {{ props.row.TotalNet }} Dhs</span>
          </div>-->
         
          
          <span class="text-nowrap"  v-else-if="props.column.field === 'DateBC'"> {{ StringTodate(props.row.DateBC) }}</span>
          
          
      
          <span v-else-if="props.column.field === 'Total'">
          <b-badge variant="light-warning">
              {{ props.row.Total }} Dhs
          </b-badge>
          </span>
          <span v-else-if="props.column.field === 'NbProduct'">
          <b-badge variant="light-primary">
              {{ props.row.NbProduct }} Unités
          </b-badge>
          </span>
          <!-- <div
          v-if="props.column.field === 'DateFact'"
          class="text-nowrap"
          >
          <span class="text-nowrap">{{ props.row.DateFact }} </span>
          </div> -->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
          <span>
              <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              >
              <template v-slot:button-content>
                  <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                  />
              </template>
              <b-dropdown-item  @click="$router.push({ name: 'BonCmdShow', params: { id: props.row.id }})">
                  <feather-icon
              :id="`invoice-row-${props.row.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-50"
              @click="$router.push({ name: 'BonCmdShow', params: { id: props.row.id }})"
          />
          <span>Télecharger</span>
          
              </b-dropdown-item>
              <b-dropdown-item @click="$router.push({ name: 'BonCmdEdit', params: { id: props.row.id }})">
                  <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                  @click="$router.push({ name: 'BonCmdEdit', params: { id: props.row.id }})"
                  />
                  <span>Modifier</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                  <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  />
                  <span>Delete</span>
              </b-dropdown-item> -->
              </b-dropdown>
          </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
          </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
          <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
              Showing 1 to
              </span>
              <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
              <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
              >
              <template #prev-text>
                  <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                  />
              </template>
              <template #next-text>
                  <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                  />
              </template>
              </b-pagination>
          </div>
          </div>
      </template>
      </vue-good-table>

  </b-card>
</div>
<div v-else>
<not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
BCard,BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,BTooltip,BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import NotAuthoriz from '../../NotAuthoriz.vue'


export default {
components: {
    BCard,
  BCardCode,BRow, BCol,
  VueGoodTable,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BButton,
  NotAuthoriz
},
data() {
  return {
    path:config.path,
    perms:[],
    loading : false,
    pageLength: 10,
    dir: false,
    columns: [
         {
        label: 'Bon N°',
        field: 'NumBC',
        filterOptions: {
          enabled: true,
          placeholder: 'N° Bon ',
        },
      }, 
     
      {
        label: 'Fournisseur',
        field: 'NomSociete',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher Fournisseur',
        },
      },
     
      {
        label: 'Quantité Produit',
        field: 'NbProduct',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher quantite',
        },
      },
      {
        label: 'Total',
        field: 'Total',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher Total',
        },
      },
      
      {
        label: 'Date',
        field: 'DateBC',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher Date',
        },
      },
      
      {
        label: 'Action',
        field: 'action',
      },
    ],
    rows: [],
    searchTerm: '',
  }
},
computed: {
  statusVariant() {
    const statusColor = {
      /* eslint-disable key-spacing */
      cheque : 'light-success',
      ESPECE     : 'light-warning',
      CHEQUE : 'light-success',
      espece     : 'light-warning',
      /* eslint-enable key-spacing */
    }

    return status => statusColor[status]
  },
  direction() {
    if (store.state.appConfig.isRTL) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = true
      return this.dir
    }
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    this.dir = false
    return this.dir
  },
},
methods:{
  StringTodate(val){
      return moment(val).format('DD/MM/YYYY');
    },
},
created() {
   this.perms=JSON.parse(localStorage.getItem('access'));
  this.loading = true
      this.$http.get(`${config.API_BASE_URL}/api/auth/fetchBonCmds`)
    .then(res => { this.rows = res.data ,this.loading = false})
},
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
